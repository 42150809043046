import React from "react";
import "./Timeline.css";
import Icofont from "react-icofont";
import { motion } from "framer-motion";

const Timeline = () => {
    return (
        <div className="tl">
            <div className="tl-content">
                <div className="tl-img">
                    <motion.img src="assets/img/timeline.png" alt="timeline"
                        initial={{ opacity: 0, filter: "blur(20px)" }}
                        whileInView={{ opacity: 1, filter: "blur(0px)" }}
                        transition={{ duration: 0.2, ease: 'easeOut' }}
                    />
                </div>
                <motion.div class="timeline"
                    initial={{ opacity: 0, y: 100 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, ease: 'easeOut' }}
                >
                    <h1>Timeline</h1>
                    <div class="timeline-container">
                        <div class="timeline-icon">
                            <Icofont icon="icofont-arrow-down" className="tl-icon" />
                        </div>
                        <div class="timeline-body">
                            <h4 class="timeline-title"><span class="badge">Date: 17.05.2024</span></h4>
                            <p>Application Open</p>
                        </div>
                    </div>
                    <div class="timeline-container">
                        <div class="timeline-icon">
                            <Icofont icon="icofont-arrow-down" className="tl-icon" />
                        </div>
                        <div class="timeline-body">
                            <h4 class="timeline-title"><span class="badge">Date: 10.06.2024</span></h4>
                            <p>Application Closed</p>
                        </div>
                    </div>
                    <div class="timeline-container">
                        <div class="timeline-icon">
                            <Icofont icon="icofont-arrow-down" className="tl-icon" />
                        </div>
                        <div class="timeline-body">
                            <h4 class="timeline-title"><span class="badge">Date: 17.06.2024</span></h4>
                            <p>Incubation Starts</p>
                        </div>
                    </div>
                    <div class="timeline-container">
                        <div class="timeline-icon">
                            <Icofont icon="icofont-arrow-down" className="tl-icon" />
                        </div>
                        <div class="timeline-body">
                            <h4 class="timeline-title"><span class="badge">Date: 20.12.2024</span></h4>
                            <p>Demo Day</p>
                        </div>
                    </div>
                </motion.div>

            </div>
        </div>
    )
}

export default Timeline;