import React from "react";
import "./Header.css";
import { motion } from "framer-motion";

const Header = () => {
  return (
    <motion.div className="header"
      initial={{ opacity: 0, y: -100 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, ease: 'easeOut' }}
    >
      <img src="assets/img/AIC_logo_black.webp" alt="" />
      <div className="apply-section">
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSca5161vBN1ugr4H8djxocBHwsJJprra262IbIAOcxAvQVmnQ/viewform?usp=sf_link"
        target="_blank">
          <button>Apply Now</button>
        </a>
      </div>
    </motion.div>
  );
};

export default Header;