import logo from './logo.svg';
import './App.css';
import Header from './components/Header/Header';
import Hero from './components/HeroSection/Hero';
import About from './components/About/About';
import PS from './components/PreferredSectors/PreferredSectors';
import PO from './components/ProgramOfferings/ProgramOfferings';
import WCA from './components/WCA/WCA';
import Timeline from './components/Timeline/Timeline';
import Partners from './components/Partners/Partners';
import Footer from './components/Footer/Footer';


function App() {
  return (
    <div className="App">
      <Header />
      <Hero />
      <About />
      <PS />
      <PO />
      <WCA />
      <Timeline />
      <Partners />
      <Footer />
    </div>
  );
}

export default App;
