import React, { useState } from "react";
import "./PO.css";
import { motion } from "framer-motion";

const PO = () => {

    return (
        <div className="po">
            <div className="po-ref">
                <motion.img src="assets/img/po_.jpg" alt="ps-ref"
                    initial={{ opacity: 0, filter: "blur(20px)" }}
                    whileInView={{ opacity: 1, filter: "blur(0px)" }}
                    transition={{ duration: 0.5, ease: 'easeOut' }}
                />
            </div>
            <motion.div className="po-content"
                initial={{ opacity: 0, y: 100 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, ease: 'easeOut' }}
            >
                <h1>Program Offerings</h1>
                <div className="po-items">
                    <ol>
                        <li>Structured Program</li>
                        <li>No Language barrier</li>
                        <li>One-on-One Mentoring</li>
                        <li>Access to Corporate Credits</li>
                        <li>“Sakhi” (Women Centric) Startup Meetups</li>
                        <li>Product Showcase</li>
                        <li>Detailed Investors Insights</li>
                        <li>Get a Chance to receive Seed Fund Support</li>
                        <li>Certificate on Completion</li>
                    </ol>
                </div>
            </motion.div>
        </div>
    );
};

export default PO;