import React from "react";
import "./WCA.css";
import { motion } from "framer-motion";

const WCA = () => {
  return (
    <div className="wca">
      <motion.div className="wca-content"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.5, ease: 'easeOut' }}
      >
        <h1>Who can apply ?</h1>
        <ul>
          <li>
            <h3>The startup/business must be led by a Woman (Founder/Co-founder)</h3>
          </li>
          <li>
            <h3>The startup/business should be at the MVP (Minimum Viable Product) stage or later.</h3>
          </li>
        </ul>
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSca5161vBN1ugr4H8djxocBHwsJJprra262IbIAOcxAvQVmnQ/viewform?usp=sf_link" target="_blank">
          <button>Apply Now</button>
        </a>
      </motion.div>
      <div className="wca-img">
        <motion.img src="assets/img/wca_.jpg" alt=""
          initial={{ opacity: 0, filter: "blur(20px)" }}
          whileInView={{ opacity: 1, filter: "blur(0px)" }}
          transition={{ duration: 0.5, ease: 'easeOut' }}
        />
      </div>
    </div>
  );
};

export default WCA;