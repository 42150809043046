import React from "react";
import "./Footer.css";
import Icofont from "react-icofont";
import { motion } from "framer-motion";

const Footer = () => {
    return (
        <motion.div className="footer"
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, ease: 'easeOut' }}
        >
            <div className="footer-content">
                <div className="footer-left-content">
                    <h2>Contact</h2>
                    <p><strong>Roshan Panigrahi</strong></p>
                    <p>Mobile No: +91 95832 19040</p>
                    <p>Email: roshan@aicnalanda.com</p>
                </div>
                <div className="footer-mid-content">
                    <h2>Social Media</h2>
                    <ul>
                        <li><a href="https://www.facebook.com/aicnalanda/" target="_blank">
                            <Icofont icon="icofont-facebook" className="icon-obj" />
                        </a></li>
                        <li><a href="https://www.instagram.com/aicnalanda/" target="_blank">
                            <Icofont icon="icofont-instagram" className="icon-obj" />
                        </a></li>
                        <li><a href="https://twitter.com/AtalNit" target="_blank">
                            <Icofont icon="icofont-twitter" className="icon-obj" />
                        </a></li>
                        <li><a href="https://www.linkedin.com/company/aic-nalanda-institute-of-technology-foundation/"
                            target="_blank">
                            <Icofont icon="icofont-linkedin" className="icon-obj" />
                        </a></li>
                        <li><a href="https://www.youtube.com/channel/UCvhudzdbhdEH0duQnhkrLJg"
                            target="_blank">
                            <Icofont icon="icofont-youtube-play" className="icon-obj" />
                        </a></li>
                    </ul>
                </div>
                <div className="footer-right-content">
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSca5161vBN1ugr4H8djxocBHwsJJprra262IbIAOcxAvQVmnQ/viewform?usp=sf_link" 
                    target="_blank">
                        <button>
                            Apply Now
                        </button>
                    </a>
                </div>
            </div>
        </motion.div>
    );
};

export default Footer;