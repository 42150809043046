import React from "react";
import "./Partners.css";
import { motion } from "framer-motion";

const Partners = () => {
    return (
        <div className="partners">
            <motion.div className="partners-content"
                initial={{ opacity: 0, y: 100 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, ease: 'easeOut' }}
            >
                <h1>Partners</h1>
                <div className="partner-logo-sec">
                    <img src="assets/img/partners/headstart_weclub.png" alt="" />
                    <img src="assets/img/partners/startup_grind.png" alt="" />
                    <img src="assets/img/partners/Scomm.png" alt="" />
                    <img src="assets/img/partners/Startup_Reporter.png" alt="" />
                </div>
            </motion.div>
            <div className="partners-img">
                <motion.img src="assets/img/partners.jpg" alt=""
                    initial={{ opacity: 0, filter: "blur(20px)" }}
                    whileInView={{ opacity: 1, filter: "blur(0px)" }}
                    transition={{ duration: 0.5, ease: 'easeOut' }}
                />
            </div>
        </div>
    )
}

export default Partners