import React from "react";
import "./Hero.css";
import { motion } from "framer-motion";

const Hero = () => {
  return (
    <div className="hero">
      <motion.img src="assets/img/hero_vritika.png" alt="" className="hero-img" 
        initial={{ opacity: 0, filter: "blur(20px)" }}
        whileInView={{ opacity: 1, filter: "blur(0px)"}}
        transition={{ duration: 0.5, ease: 'easeOut' }}
      />
      <motion.div className="hero-image-sec"
        initial={{ opacity: 0, y: 100 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, ease: 'easeOut' }}
      >
        <img src="assets/img/vritika_logo.png" alt="" />
      </motion.div>
    </div>
  );
};

export default Hero;