import React from "react";
import "./About.css";
import { motion } from "framer-motion";

const About = () => {
    return (
        <div className="about">
            <div className="about-img-sec">
                <motion.img src="assets/img/about.jpeg" alt=""
                    initial={{ opacity: 0, filter: "blur(20px)" }}
                    whileInView={{ opacity: 1, filter: "blur(0px)" }}
                    transition={{ duration: 0.5, ease: 'easeIn' }}
                />
            </div>
            <div className="about-content-sec">
                <motion.div className="about-inner-sec"
                    initial={{ opacity: 0, y: 100 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.2, ease: 'easeIn' }}
                >
                    <h1>About</h1>
                    <p>
                        VRITIKA-WCIP is one of its kind initiatives of AIC-NITF which is a 6-month incubation program specially curated for women entrepreneurs at Minimum Viable Product (MVP), Early Traction, and Early Market Entry Stage, in the months of June-December 2024.
                        <br />
                        <br />
                        VRITIKA, a Women Centric Incubation Program (VRITIKA-WCIP), is a targeted initiative designed to equip aspiring and established women entrepreneurs. Serving as a guiding force, WCIP nurtures an entrepreneurial mindset, motivating women to elevate their enterprises to the next level. The 6-month incubation Program empowers women entrepreneurs at various stages, from Minimum Viable Product (MVP) to early traction or market entry.
                    </p>
                </motion.div>
            </div>
        </div>
    );
};

export default About;