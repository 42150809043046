import React, { useState } from "react";
import { motion } from "framer-motion";
import "./PS.css";


const PS = () => {

    const [activeImage, setActiveImage] = useState(
        "assets/img/PS_Imgs/food.jpg"
    );

    return (
        <div className="ps">
            <motion.div className="ps-content"
                initial={{ opacity: 0, y: 100 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, ease: 'easeOut' }}
            >
                <h1>Preferred Sectors</h1>
                <div className="ps-items">
                    <ol>
                        <li
                            onMouseEnter={() => setActiveImage("assets/img/PS_Imgs/food.jpg")}
                        >Food & Nutrition</li>
                        <li
                            onMouseEnter={() => setActiveImage("assets/img/PS_Imgs/beauty_alt.jpg")}
                        >Beauty & Wellness</li>
                        <li
                            onMouseEnter={() => setActiveImage("assets/img/PS_Imgs/health.jpg")}
                        >Health</li>
                        <li
                            onMouseEnter={() => setActiveImage("assets/img/PS_Imgs/livelyhood.jpg")}
                        >Livelihood</li>
                        <li
                            onMouseEnter={() => setActiveImage("assets/img/PS_Imgs/education_.jpg")}
                        >Education</li>
                        <li
                            onMouseEnter={() => setActiveImage("assets/img/PS_Imgs/art_and_craft_alt.png")}
                        >Art & Crafts</li>
                    </ol>
                </div>
            </motion.div>
            <div className="ps-ref">
                <motion.img src={activeImage} alt="ps-ref"
                    initial={{ opacity: 0, filter: "blur(20px)" }}
                    whileInView={{ opacity: 1, filter: "blur(0px)" }}
                    transition={{ duration: 0.5, ease: 'easeOut' }}
                />
            </div>
        </div>
    );
};

export default PS;